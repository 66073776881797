import React, { forwardRef } from 'react';
import ReactDatePicker from 'react-datepicker';
import { useSelector } from 'react-redux';
import { cn } from '../../utils';

const DPCustomInput = forwardRef(
  (
    { value, onClick, placeholderText, disabled, dataTestId = '', ...props },
    ref,
  ) => (
    <input
      data-test-id={dataTestId}
      ref={ref}
      value={value}
      onClick={onClick}
      readOnly={true}
      placeholder={placeholderText}
      className={cn(
        'border cursor-pointer text-center w-full rounded  hover:bg-gfPeriwinkle  border-gfPeriwinkle px-8 h-10 focus:outline-none',
        {
          'bg-gfPeriwinkle': disabled,
        },
      )}
    />
  ),
);

export default forwardRef(function DatePickerField(
  {
    label,
    dateFormat = 'Pp',
    timeFormat = 'HH:mm',
    showMonthDropdown = true,
    showYearDropdown = true,
    showTimeSelect = false,
    disabled = false,
    dataTestId = '',
    ...props
  },
  ref,
) {
  const { selectedLanguage } = useSelector((state) => state.language);

  let handleColor = (time) => {
    return time.getHours() > 12 ? 'text-success' : 'text-error';
  };

  return (
    <div className="flex flex-col">
      <div className="flex items-center py-1">{label}:</div>
      <ReactDatePicker
        {...props}
        showMonthDropdown={showMonthDropdown}
        showYearDropdown={showYearDropdown}
        dateFormat={dateFormat}
        timeFormat={timeFormat}
        showTimeSelect={showTimeSelect}
        disabled={disabled}
        customInput={<DPCustomInput ref={ref} dataTestId={dataTestId} />}
        locale={selectedLanguage.value}
        timeClassName={handleColor}
      />
    </div>
  );
});
