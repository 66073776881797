import React, { useState, useMemo, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Container from '../../../UIComponents/Container';
import { useExportShops, useShop, useShops } from '../../../../hooks/useShop';
import { acceptanceStatus, editMode } from '../../../../enums/shop';
import Table from '../../../Table';
import ActiveIcon from '../../../../assets/svg/ActiveIcon';
import DeclineIcon from '../../../../assets/svg/DeclineIcon';
import PendingIcon from '../../../../assets/svg/PendingIcon';
import ViewTerminalsModal from './ViewTerminalsModal';
import MessageAlert from '../../../Alert/MessageAlert';
import FailAlert from '../../../Alert/FailAlert';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { FormControl, FormField, FormItem, FormLabel } from '../../../Form';
import SelectField from '../../../Input/SelectField';
import useAppConfig from '../../../../hooks/useAppConfig';
import { Pagination } from '../../../Pagination';
import EditIcon from '../../../../assets/svg/EditIcon';
import { Button } from '../../../Button';
import EditShopModal from './EditShopModal';
import { useShoppingCentres } from '../../../../hooks/useShoppingCentres';
import { useDebounce } from '../../../../hooks/useDebounce';
import { MerchantCodeDescription } from '../../../Merchant';
import SearchField from '../../../Input/SearchField';
import { exportExcelFile } from '../../../../utils/Helper';
import { useSearchParams } from '../../../../hooks/useSearchParams';
import { RESET_SHOPS } from '../../../../actions/Shops/ListOfShopsActions';
import { useDispatch } from 'react-redux';

function StatusIcon({ status, size }) {
  const iconProps = {
    w: size,
    h: size,
  };

  if (status === 'accepted') {
    return <ActiveIcon {...iconProps} />;
  }

  if (status === 'rejected') {
    return <DeclineIcon {...iconProps} />;
  }

  return <PendingIcon {...iconProps} />;
}

function Counter({ status, value }) {
  const { t } = useTranslation();

  const label = useMemo(
    () => t(`shops.acceptance_status.${status}`),
    [t, status],
  );

  return (
    <div className="flex flex-row items-center justify-start gap-x-2">
      <StatusIcon status={status} size={20} />
      <span className="uppercase">{label}:</span>
      <span className="text-xl">{value}</span>
    </div>
  );
}

function Row({
  shop,
  showLocation,
  onClickViewTerminals,
  onDecline,
  onAuthorize,
  onEdit,
}) {
  const { t } = useTranslation();
  const { status, serialNumbers } = shop;

  const hasMultipleTerminals = useMemo(
    () => serialNumbers.length > 1,
    [serialNumbers],
  );
  const terminalsText = useMemo(() => {
    if (serialNumbers.length === 0) {
      return '-';
    }

    return serialNumbers[0];
  }, [serialNumbers]);

  const isAuthorized = useMemo(
    () => status === acceptanceStatus.ACCEPTED,
    [status],
  );

  const canDecline = useMemo(
    () =>
      [acceptanceStatus.UNCONFIRMED, acceptanceStatus.ACCEPTED].includes(
        status,
      ),
    [status],
  );

  const canAuthorize = useMemo(
    () =>
      [acceptanceStatus.UNCONFIRMED, acceptanceStatus.REJECTED].includes(
        status,
      ),
    [status],
  );

  return (
    <Table.Row>
      <Table.Cell>
        <div className="flex items-center justify-center">
          <StatusIcon status={shop.status} size={20} />
        </div>
      </Table.Cell>
      {showLocation && <Table.Cell>{shop.location?.name || '-'}</Table.Cell>}
      <Table.Cell>{shop.merchant?.name || '-'}</Table.Cell>
      <Table.Cell>{shop.name || '-'}</Table.Cell>
      <Table.Cell>{shop.acquirer?.code || '-'}</Table.Cell>
      <Table.Cell>
        <MerchantCodeDescription code={shop.acquirer?.code} />
      </Table.Cell>
      <Table.Cell>{shop.created_at || '-'}</Table.Cell>
      <Table.Cell>{shop.acceptation_status_changed_date || '-'}</Table.Cell>
      <Table.Cell>
        <span>{terminalsText}</span>
        {hasMultipleTerminals && (
          <>
            <span>, </span>
            <button type={'button'} onClick={() => onClickViewTerminals(shop)}>
              {t('More')}...
            </button>
          </>
        )}
      </Table.Cell>
      <Table.Cell>
        <div className="flex flex-row items-center justify-center space-x-2">
          {canAuthorize && (
            <Button
              type={'button'}
              variant="link"
              onClick={() => onAuthorize(shop)}
            >
              {t('shops.manage.actions.authorize')}
            </Button>
          )}
          {canDecline && (
            <Button
              type={'button'}
              variant="link-destructive"
              onClick={() => onDecline(shop)}
            >
              {t('shops.manage.actions.decline')}
            </Button>
          )}
          {isAuthorized && (
            <button type={'button'} onClick={() => onEdit(shop)}>
              <EditIcon />
            </button>
          )}
        </div>
      </Table.Cell>
    </Table.Row>
  );
}

function Filters({ onSubmit }) {
  const { t } = useTranslation();
  const form = useRef(null);
  const { handleSubmit } = useFormContext();

  const statusOptions = useMemo(
    () => [
      { value: null, label: t('shops.acceptance_status.all') },
      {
        value: acceptanceStatus.ACCEPTED,
        label: t('shops.acceptance_status.accepted'),
      },
      {
        value: acceptanceStatus.REJECTED,
        label: t('shops.acceptance_status.rejected'),
      },
      {
        value: acceptanceStatus.UNCONFIRMED,
        label: t('shops.acceptance_status.unconfirmed'),
      },
    ],
    [t],
  );

  const debouncedSubmit = useDebounce(() => {
    form.current.requestSubmit();
  }, 250);

  return (
    <form
      className="grid grid-cols-1 md:grid-cols-2 md:gap-12 lg:grid-cols-4"
      onSubmit={handleSubmit(onSubmit)}
      ref={form}
    >
      <FormField
        name="query"
        render={(field) => {
          function onSearch(value) {
            field.onChange(value);
            debouncedSubmit();
          }

          return (
            <FormItem horizontal>
              <FormControl>
                <SearchField
                  ref={field.ref}
                  value={field.value}
                  className="w-full"
                  onChange={onSearch}
                  onClear={() => {
                    field.onChange('');
                    form.current.requestSubmit();
                  }}
                />
              </FormControl>
            </FormItem>
          );
        }}
      />
      <FormField
        name="status"
        render={(field) => {
          const selectedOption = statusOptions.find(
            (option) => field.value === option.value,
          );

          return (
            <FormItem horizontal>
              <FormLabel>{t('shops.manage.filters.status')}:</FormLabel>
              <FormControl>
                <SelectField
                  options={statusOptions}
                  inputRef={field.ref}
                  value={selectedOption}
                  onChange={(option) => {
                    field.onChange(option.value);
                    form.current.requestSubmit();
                  }}
                  className="w-full"
                />
              </FormControl>
            </FormItem>
          );
        }}
      />
    </form>
  );
}

export default function Page() {
  const { t } = useTranslation();
  const { getConfigValue } = useAppConfig();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const perPage = getConfigValue('REACT_APP_PAGINATION_PER_PAGE_ROWS');
  const { shoppingCentresForBrand } = useShoppingCentres();
  const { updateAcceptanceStatus } = useShop();
  const { fetch: fetchExport, isLoading: isLoadingExport } = useExportShops();
  const [terminalsForShopId, setTerminalsForShopId] = useState(null);
  const [sorting, setSorting] = useState({
    id: 'lastModificationDate',
    direction: 'desc',
  });
  const [editShop, setEditShop] = useState({
    mode: editMode.EDIT,
    shopId: null,
  });

  const keyword = searchParams.get('keyword');
  const defaultValues = useMemo(() => {
    if (keyword) {
      return {
        status: null,
        query: keyword,
      };
    }

    return {
      status: acceptanceStatus.UNCONFIRMED,
      query: '',
    };
  }, [keyword]);

  const {
    shops,
    isLoading,
    isFetched,
    isError,
    error,
    fetch,
    pagination,
    countByStatus,
    findById,
  } = useShops();

  const formMethods = useForm({
    mode: 'onChange',
    defaultValues,
  });

  useEffect(() => {
    dispatch({ type: RESET_SHOPS });
    fetch({
      ...defaultValues,
      sort: sorting.id,
      sort_direction: sorting.direction,
      page: 1,
      perPage,
    });
  }, [fetch, dispatch, defaultValues, sorting, perPage, keyword]);

  const shouldDisplayLocation = useMemo(
    () => shoppingCentresForBrand.length > 1,
    [shoppingCentresForBrand],
  );

  const columns = useMemo(
    () =>
      [
        { accessorKey: 'status', header: t('shops.manage.results.status') },
        {
          accessorKey: 'location',
          header: t('shops.manage.results.location'),
          hidden: !shouldDisplayLocation,
        },
        {
          accessorKey: 'name',
          header: t('shops.manage.results.name'),
          enableSorting: true,
        },
        {
          accessorKey: 'acquirerName',
          header: t('shops.manage.results.acquirer_name'),
          enableSorting: true,
        },
        { accessorKey: 'mcc', header: t('shops.manage.results.mcc') },
        {
          accessorKey: 'mcc_description',
          header: t('shops.manage.results.mcc_description'),
        },
        {
          accessorKey: 'creationDate',
          header: t('shops.manage.results.creation_date'),
          enableSorting: true,
        },
        {
          accessorKey: 'lastModificationDate',
          header: t('shops.manage.results.last_modification_date'),
          enableSorting: true,
        },
        {
          accessorKey: 'terminals',
          header: t('shops.manage.results.terminals'),
        },
        { accessorKey: 'actions', header: t('shops.manage.results.actions') },
      ].filter((column) => !column.hidden),
    [t, shouldDisplayLocation],
  );

  function refetch(params = {}) {
    fetch({
      sort: sorting.id,
      sort_direction: sorting.direction,
      ...formMethods.getValues(),
      perPage,
      page: pagination.currentPage,
      ...params,
    });
  }

  function onChangeFilters(data) {
    fetch({
      sort: sorting.id,
      sort_direction: sorting.direction,
      status: data.status,
      query: data.query,
      perPage,
    });
  }

  function onPaginate({ selected }) {
    fetch({
      ...formMethods.getValues(),
      sort: sorting.id,
      sort_direction: sorting.direction,
      perPage,
      page: selected + 1,
    });
  }

  async function onDecline(shop) {
    await updateAcceptanceStatus(shop, acceptanceStatus.REJECTED);
    refetch();
  }

  function onAuthorize(shop) {
    setEditShop({
      shopId: shop.id,
      mode: editMode.AUTHORIZE,
    });
  }

  function onEdit(shop) {
    setEditShop({
      shopId: shop.id,
      mode: editMode.EDIT,
    });
  }

  async function onExport() {
    const response = await fetchExport({
      ...formMethods.getValues(),
      sort: sorting.id,
      sort_direction: sorting.direction,
    });

    exportExcelFile(response.data, 'shops');
  }

  function onClickViewTerminals(shop) {
    setTerminalsForShopId(shop.id);
  }

  function onSortingChange(sortKey, direction) {
    setSorting({
      id: sortKey,
      direction,
    });
  }

  const terminalsForShop = useMemo(
    () => (terminalsForShopId ? findById(terminalsForShopId) : null),
    [findById, terminalsForShopId],
  );

  const editingShop = useMemo(
    () => (editShop.shopId ? findById(editShop.shopId) : null),
    [findById, editShop],
  );

  return (
    <Container title={t('shops.manage.title')} loading={isLoading}>
      <FormProvider {...formMethods}>
        <div className="space-y-4">
          <Filters onSubmit={onChangeFilters} />
          {isFetched && shops.length === 0 && (
            <MessageAlert message={t('shops.manage.no_results')} />
          )}
          {isError && <FailAlert message={error} />}
          {shops.length > 0 && (
            <>
              <div className="flex flex-row gap-x-12">
                {Object.keys(countByStatus).map((status) => (
                  <Counter
                    key={status}
                    status={status}
                    value={countByStatus[status] || 0}
                  />
                ))}
                <div className="ml-auto">
                  <Button
                    variant="primary"
                    onClick={onExport}
                    loading={isLoadingExport}
                  >
                    {t('export-excel')}
                  </Button>
                </div>
              </div>
              {pagination.isPaginated && (
                <div className="flex flex-col md:flex-row md:justify-end w-full">
                  <Pagination
                    pageCount={pagination.totalPages}
                    onPageChange={onPaginate}
                  />
                </div>
              )}
              <div className="shadow overflow-x-auto sm:overflow-x-auto md:overflow-x-auto rounded border">
                <Table>
                  <Table.Head>
                    <tr>
                      {columns.map((column) => {
                        if (column.enableSorting) {
                          const isSorted = sorting.id === column.accessorKey;

                          return (
                            <Table.SortableHeader
                              key={column.accessorKey}
                              onSort={onSortingChange}
                              sortKey={column.accessorKey}
                              isSorted={isSorted}
                              direction={isSorted ? sorting.direction : null}
                            >
                              {column.header}
                            </Table.SortableHeader>
                          );
                        }

                        return (
                          <Table.Header key={column.accessorKey}>
                            {column.header}
                          </Table.Header>
                        );
                      })}
                    </tr>
                  </Table.Head>
                  <Table.Body>
                    {shops.map((shop) => (
                      <Row
                        key={shop.id}
                        shop={shop}
                        onClickViewTerminals={onClickViewTerminals}
                        onDecline={onDecline}
                        onAuthorize={onAuthorize}
                        onEdit={onEdit}
                        showLocation={shouldDisplayLocation}
                      />
                    ))}
                  </Table.Body>
                </Table>
              </div>
            </>
          )}
          {terminalsForShop && (
            <ViewTerminalsModal
              shop={terminalsForShop}
              onClose={() => setTerminalsForShopId(null)}
            />
          )}
          {editingShop && (
            <EditShopModal
              shop={editingShop}
              mode={editShop.mode}
              onClose={() =>
                setEditShop({
                  mode: editMode.EDIT,
                  shopId: null,
                })
              }
              onSuccess={() => {
                refetch();
              }}
            />
          )}
        </div>
      </FormProvider>
    </Container>
  );
}
