import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import TabItemContainer from '../../../UIComponents/TabItemContainer';
import Table from '../../../Table';
import {
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Form,
} from '../../../Form';
import DatePickerField from '../../../Input/DatePickerField';
import Button from '../../../UIComponents/Button';
import useApiHook from '../../../../hooks/useAPI';
import reportAPI from '../../../../api/reportAPI';
import { checkLoggedInUserPermissions } from '../../../../utils/Helper';

function ReportsList({ month, monthNum, chooseYear }) {
  const { t } = useTranslation();

  const [purchaserExportHandler, getPurchaserExport] = useApiHook(
    reportAPI.getExcelReportPurchaser,
  );

  const [recipientExportHandler, getRecipientExport] = useApiHook(
    reportAPI.getExcelReportRecipient,
  );

  const filter = {
    chooseYear: chooseYear.getFullYear(),
    monthNum,
  };

  const isPurchaserEnable = useMemo(
    () => checkLoggedInUserPermissions('purchaser-report'),
    [],
  );
  const isRecipientEnable = useMemo(
    () => checkLoggedInUserPermissions('recipient-report'),
    [],
  );

  return (
    <Table.Row>
      <Table.Cell>
        <div className="text-gfLightBlue inline-block align-middle">
          {t(month)}
        </div>
      </Table.Cell>
      {isPurchaserEnable && (
        <Table.Cell>
          <Button
            onClick={() => getPurchaserExport(filter)}
            label={t('Download')}
            classAddsName="text-center mx-auto"
            loading={purchaserExportHandler.loading}
          />
        </Table.Cell>
      )}
      {isRecipientEnable && (
        <Table.Cell>
          <Button
            onClick={() => getRecipientExport(filter)}
            label={t('Download')}
            classAddsName="text-center mx-auto"
            loading={recipientExportHandler.loading}
          />
        </Table.Cell>
      )}
    </Table.Row>
  );
}

export default function PurchaserRecipient() {
  const { t } = useTranslation();
  const [chooseYear, setChooseYear] = useState(new Date());
  const [displayList, setDisplayList] = useState(true);
  const methods = useForm({
    shouldUnregister: false,
    mode: 'onChange',
    defaultValues: {
      fromDate: new Date(),
      toDate: null,
    },
  });

  const currentDate = new Date();
  const handleSubmit = () => {
    setDisplayList(true);
  };
  const fromDatePickHandler = (date) => {
    setDisplayList(false);
    setChooseYear(date);
  };

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  return (
    <TabItemContainer
      title={t('reports.purchaser-recipient.report.title')}
      description={t('reports.purchaser-recipient.report.description')}
    >
      <div className="flex">
        <Form {...methods}>
          <div className="grid grid-cols-2 md:grid-cols-5 gap-3 items-end mb-4">
            <div className="flex flex-col">
              <FormField
                name="fromDate"
                render={(field) => (
                  <FormItem>
                    <FormControl>
                      <DatePickerField
                        {...field}
                        dateFormat="yyyy"
                        label={t('Year')}
                        maxDate={currentDate}
                        showYearPicker
                        selected={chooseYear}
                        onChange={(date) => fromDatePickHandler(date)}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <Button variant="primary" onClick={handleSubmit} label={t('Go')} />
          </div>
        </Form>
      </div>
      {displayList && (
        <Table>
          <Table.Head>
            <tr>
              <Table.Header className="w-1/3">{t('Month')}</Table.Header>
              {checkLoggedInUserPermissions('purchaser-report') && (
                <Table.Header className="w-1/3">{t('Purchaser')}</Table.Header>
              )}
              {checkLoggedInUserPermissions('recipient-report') && (
                <Table.Header className="w-1/3">{t('Recipient')}</Table.Header>
              )}
            </tr>
          </Table.Head>
          <Table.Body>
            {months.map(
              (month, index) =>
                (chooseYear.getFullYear() < currentDate.getFullYear() ||
                  (chooseYear.getFullYear() <= currentDate.getFullYear() &&
                    index <= currentDate.getMonth())) && (
                  <ReportsList
                    month={month}
                    monthNum={index + 1}
                    chooseYear={chooseYear}
                    key={index}
                  />
                ),
            )}
          </Table.Body>
        </Table>
      )}
    </TabItemContainer>
  );
}
