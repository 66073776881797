import React, { useMemo, useState } from 'react';
import Table from '../../../Table';
import Print from '../../../../assets/svg/Print';
import ChevronUp from '../../../../assets/svg/ChevronUp';
import ChevronDown from '../../../../assets/svg/ChevronDown';
import { Link } from 'react-router-dom';
import { checkLoggedInUserPermissions } from '../../../../utils/Helper';
import ShoppingCenterMoney from '../../../ShoppingCenterMoney';
import ReconciliationModal from '../../../Modal/ReconciliationModal';
import { useSelector } from 'react-redux';

const getTokenToDisplay = (tokens) => {
  if (
    tokens.length === 1 &&
    (!tokens[0].token_number || tokens[0].token_number === '0')
  ) {
    return '';
  }

  return tokens.length === 1 && tokens[0].token_number ? (
    <Link
      className="underline"
      to={`/gift-cards/card-information?token=${tokens[0].token_number}`}
    >
      {tokens[0].token_number}
    </Link>
  ) : (
    `${tokens.length}x`
  );
};

function PaymentMethods({ payments, reconData }) {
  const [openReconPopup, setOpenOpenReconPopup] = useState(false);

  const canAccessReconciliationData = useMemo(
    () => checkLoggedInUserPermissions('daily-loads-with-reconciliation'),
    [checkLoggedInUserPermissions],
  );

  const closeHandlerReconDataModal = () => {
    setOpenOpenReconPopup(!openReconPopup);
  };

  return (
    <div className="flex flex-col justify-center items-center">
      {payments.map((payment) => (
        <div className="flex flex-row">
          {canAccessReconciliationData &&
          reconData &&
          reconData.length !== 0 ? (
            <div
              onClick={() => {
                setOpenOpenReconPopup(!openReconPopup);
              }}
              className="underline cursor-pointer"
            >{`${payment.payment_method}`}</div>
          ) : (
            `${payment.payment_method}`
          )}
          <span>
            (<ShoppingCenterMoney value={payment.amount} />)
          </span>
        </div>
      ))}
      <ReconciliationModal
        isOpen={openReconPopup}
        onClose={closeHandlerReconDataModal}
        data={reconData}
      />
    </div>
  );
}

function AdditionalProducts({ products, total }) {
  return (
    <div className="flex flex-col justify-center items-center">
      <div className="font-MulishBold">
        <ShoppingCenterMoney value={total} />
      </div>
      {products.map((product) => (
        <div>
          <ShoppingCenterMoney value={product.price} /> x{`${product.quantity}`}
        </div>
      ))}
    </div>
  );
}

function checkOrderHasAssignedToken(tokens) {
  return !!tokens.find((token) => token.token_number !== '0');
}

export default function Row({
  setRowOpen,
  setOrderDetails,
  setIsOrderDetailsModalOpened,
  sale,
  rowIsOpen,
}) {
  const { selectedTag } = useSelector((state) => state.tag);

  return (
    <>
      <Table.Row key={sale.id}>
        <Table.Cell>{sale.date}</Table.Cell>
        <Table.Cell>
          {!sale.order_reference?.length ? sale.ticket_number : '-'}
        </Table.Cell>
        <Table.Cell>{sale.order_reference || '-'}</Table.Cell>
        <Table.Cell>{sale.channel || '-'}</Table.Cell>
        <Table.Cell>{getTokenToDisplay(sale.tokens)}</Table.Cell>
        <Table.Cell>
          <ShoppingCenterMoney value={sale.card_load_value_total} />
        </Table.Cell>
        {selectedTag.purchase_fee_enabled && (
          <Table.Cell>
            <ShoppingCenterMoney value={sale.card_load_fee_total} />
          </Table.Cell>
        )}
        {selectedTag.additional_products_enabled &&
          selectedTag.has_additional_products && (
            <Table.Cell>
              {sale.additional_products.length !== 0 ? (
                <AdditionalProducts
                  products={sale.additional_products}
                  total={sale.additional_products_total}
                />
              ) : (
                '-'
              )}
            </Table.Cell>
          )}
        <Table.Cell>
          {sale.payments?.length !== 0 ? (
            <PaymentMethods
              reconData={sale.reconciliation_data}
              payments={sale.payments}
              total={sale.payment_amount_total}
            />
          ) : (
            '-'
          )}
        </Table.Cell>
        <Table.Cell>
          <ShoppingCenterMoney value={sale.payment_amount_total} />
        </Table.Cell>
        <Table.Cell className={'flex justify-center items-center'}>
          {sale?.tokens.length !== 0 &&
            checkOrderHasAssignedToken(sale.tokens) && (
              <button
                className="focus:outline-none mr-3"
                onClick={() => {
                  setIsOrderDetailsModalOpened(true);
                  setOrderDetails(sale);
                }}
              >
                <Print />
              </button>
            )}

          {sale.tokens.length > 1 ? (
            <button
              className="focus:outline-none"
              id={sale.ticket_number}
              onClick={() => setRowOpen(rowIsOpen ? null : sale.ticket_number)}
            >
              {rowIsOpen ? <ChevronUp /> : <ChevronDown />}
            </button>
          ) : (
            ''
          )}
        </Table.Cell>
      </Table.Row>
      {rowIsOpen &&
        sale.tokens.map((token) => (
          <Table.Row
            className={'bg-gray-100'}
            data-testid={token}
            key={token.token_number}
          >
            <Table.Cell data-testid="date"></Table.Cell>
            <Table.Cell data-testid="ticketNumber"></Table.Cell>
            <Table.Cell data-testid="orderRef"></Table.Cell>
            <Table.Cell data-testid="user"></Table.Cell>
            <Table.Cell className={'flex'} data-testid="tokens">
              {!!token.token_number && token.token_number !== '0' && (
                <div className={'flex'}>
                  <Link
                    className="underline"
                    to={`/gift-cards/card-information?token=${token.token_number}`}
                  >
                    {token.token_number}
                  </Link>
                  <button
                    className="focus:outline-none ml-2"
                    onClick={() => {
                      setIsOrderDetailsModalOpened(true);
                      setOrderDetails({ token: token.token_number, ...sale });
                    }}
                  >
                    <Print />
                  </button>
                </div>
              )}
            </Table.Cell>
            <Table.Cell>
              <ShoppingCenterMoney value={token.amount} />
            </Table.Cell>
            {selectedTag.purchase_fee_enabled && (
              <Table.Cell>
                <ShoppingCenterMoney value={token.fee} />
              </Table.Cell>
            )}
            {selectedTag.additional_products_enabled &&
              selectedTag.has_additional_products && <Table.Cell></Table.Cell>}
            <Table.Cell></Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell></Table.Cell>
          </Table.Row>
        ))}
    </>
  );
}
