import React, { forwardRef } from 'react';
import { cn } from '../../utils';
import Input from './index';

const TextField = forwardRef(function (
  { className, error = null, ...props },
  ref,
) {
  return (
    <Input
      ref={ref}
      {...props}
      className={cn(
        'rounded-md',
        {
          'border-red-400': !!error,
        },
        className,
      )}
    />
  );
});

export default TextField;
