import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import ReactPaginate from 'react-paginate';
import Table from '../../../Table';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import SummariesByChannel from './SummariesByChannel';
import SummaryModal from './SummaryModal';
import Row from './Row';
import Summaries from './Summaries';
import Filter from '../Filter';
import useApiHook from '../../../../hooks/useAPI';
import reportAPI from '../../../../api/reportAPI';
import AlertHandler from '../../../Alert/AlertHandler';
import TabItemContainer from '../../../UIComponents/TabItemContainer';
import { useSelector } from 'react-redux';

export default function AllSales() {
  const { t } = useTranslation();
  const [rowOpen, setRowOpen] = useState(null);
  const [isOrderDetailsModalOpened, setIsOrderDetailsModalOpened] =
    useState(false);
  const [filter, setFilter] = useState({});
  const [orderDetails, setOrderDetails] = useState(false);
  const pageRef = useRef(null);
  const { selectedTag } = useSelector((state) => state.tag);

  useEffect(() => {
    getSalesList({});
  }, []);

  const [allSalesHandler, getAllSales] = useApiHook(reportAPI.getAllSales);
  const [allSalesSummaryHandler, getAllSalesSummary] = useApiHook(
    reportAPI.getAllSalesSummary,
  );

  const pageCount = useMemo(() => {
    return (
      allSalesHandler.data?.meta?.total >
      window?.appConfig?.REACT_APP_PAGINATION_PER_PAGE_ROWS
    );
  }, [allSalesHandler.data?.meta?.total]);

  const [excelReport, getExcelReport] = useApiHook(
    reportAPI.getExcelReportSales,
  );

  const getSalesList = useCallback(
    ({ fromDate = null, toDate = null, channel = null, page = 1 }) => {
      const params = {
        from_date: format(fromDate ?? new Date(), 'yyyy-MM-dd'),
        to_date: format(toDate ?? new Date(), 'yyyy-MM-dd'),
        page: page,
        per_page: window?.appConfig?.REACT_APP_PAGINATION_PER_PAGE_ROWS,
      };

      if (channel && channel.id !== -1) {
        params.channel_id = channel.id;
      }

      getAllSales(params);
      getAllSalesSummary(params);
    },
    [],
  );

  const handlePageClick = (page) => {
    const params = {
      from_date: format(filter.fromDate ?? new Date(), 'yyyy-MM-dd'),
      to_date: format(filter.toDate ?? new Date(), 'yyyy-MM-dd'),
      page: page.selected + 1,
      per_page: window?.appConfig?.REACT_APP_PAGINATION_PER_PAGE_ROWS,
    };
    getAllSales(params);
  };

  return (
    <TabItemContainer
      title={t('reports.sales.all-sales.title')}
      description={t('reports.sales.all-sales.description')}
    >
      <Filter
        onFilter={(data) => {
          getSalesList(data);
          setFilter(data);
        }}
        status={allSalesHandler}
        channel={true}
      />
      {!!allSalesSummaryHandler?.data &&
        !!allSalesHandler.data?.data?.length && (
          <Summaries
            summary={allSalesSummaryHandler.data}
            cards={[
              {
                bottomLabel: t('Number-of-transactions'),
                value: allSalesSummaryHandler?.data?.transactions_total,
              },
              {
                bottomLabel: t('Total-amount'),
                isCurrency: true,
                value: allSalesSummaryHandler?.data?.total_amount,
              },
              {
                bottomLabel: t('Average-Loaf'),
                isCurrency: true,
                value: allSalesSummaryHandler?.data?.card_total,
              },
            ]}
          />
        )}
      {!!allSalesSummaryHandler?.data?.channel_summary?.length && (
        <SummariesByChannel
          summary={allSalesSummaryHandler.data.channel_summary}
        />
      )}
      {!!allSalesHandler.data?.data?.length &&
        allSalesSummaryHandler.success && (
          <div className={'flex justify-between my-3'}>
            <>
              <button
                className={
                  'px-4 py-2 rounded focus:outline-none bg-gfCoral flex flex-row justify-center items-center font-MulishBold'
                }
                onClick={() => getExcelReport(filter)}
              >
                {t('export-excel')}
              </button>
              {pageCount && (
                <div>
                  <ReactPaginate
                    ref={pageRef}
                    previousLabel={'prev'}
                    nextLabel={'next'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={
                      allSalesHandler.data?.meta?.total /
                      window?.appConfig?.REACT_APP_PAGINATION_PER_PAGE_ROWS
                    }
                    marginPagesDisplayed={4}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                  />
                </div>
              )}
            </>
          </div>
        )}

      {!!allSalesHandler.data?.data?.length && allSalesHandler.success && (
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Header>{t('Date')}</Table.Header>
              <Table.Header>{t('Ticket-number')}</Table.Header>
              <Table.Header>{t('global-order-ref')}</Table.Header>
              <Table.Header>{t('channel')}</Table.Header>
              <Table.Header>{t('Token')}</Table.Header>
              <Table.Header>{t('Card-load')}</Table.Header>
              {selectedTag.purchase_fee_enabled && (
                <Table.Header>{t('card-load-fee')}</Table.Header>
              )}
              {selectedTag.additional_products_enabled &&
                selectedTag.has_additional_products && (
                  <Table.Header>{t('Additional-product')}</Table.Header>
                )}
              <Table.Header>{t('Payment-method')}</Table.Header>
              <Table.Header>{t('Amount')}</Table.Header>
              <Table.Header>{t('Action')}</Table.Header>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {allSalesHandler?.data?.data?.map((sale) => (
              <Row
                key={sale.ticket_number}
                sale={sale}
                rowIsOpen={rowOpen === sale.ticket_number}
                setRowOpen={setRowOpen}
                setOrderDetails={setOrderDetails}
                setIsOrderDetailsModalOpened={setIsOrderDetailsModalOpened}
              />
            ))}
          </Table.Body>
        </Table>
      )}
      <AlertHandler handler={allSalesHandler} />

      <SummaryModal
        ticket={orderDetails}
        isOpen={isOrderDetailsModalOpened}
        onClose={() => setIsOrderDetailsModalOpened(false)}
      />
    </TabItemContainer>
  );
}
