import React, { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Money from '../../../Money';
import { DefaultReceipt, ReceiptField, ReceiptTitleH1 } from '../../../Receipt';

const AdditionalProducts = (product) => {
  const { tagCurrency } = useSelector((state) => state.tag);
  const { t } = useTranslation();

  return (
    <div className="my-2">
      <div className="flex flex-row justify-start item-center">
        <ReceiptField name={t('Quantity')} value={product.quantity} />
      </div>
      <div className="flex flex-row justify-start item-center">
        <ReceiptField
          name={t('price')}
          value={<Money value={product.price} currency={tagCurrency} />}
        />
      </div>
    </div>
  );
};

export default forwardRef(function Receipt({ loads }, ref) {
  const { tagCurrency } = useSelector((state) => state.tag);
  const { t } = useTranslation();

  return (
    <div className="bg-gray-100" ref={ref}>
      <DefaultReceipt>
        {loads?.user && loads?.user !== '' && (
          <ReceiptField
            name={t('User')}
            value={`${loads?.user ? loads?.user : '-'}`}
          />
        )}

        <ReceiptField name={t('Transaction')} value={loads.ticket_number} />

        {loads.desk && (
          <ReceiptField name={t('Desk')} value={loads.desk.name} />
        )}

        <ReceiptField name={t('Date-of-Purchase')} value={loads.date} />

        {loads?.payments && loads.payments.length !== 0 && (
          <div className="my-5">
            <ReceiptTitleH1 title={t('Payment-methods')} />
            {loads.payments.map((payment) => (
              <ReceiptField
                name={payment.paymentMethod}
                value={<Money value={payment.amount} currency={tagCurrency} />}
              />
            ))}
          </div>
        )}

        {loads.tokens && loads.tokens.length !== 0 && (
          <div className="">
            <ReceiptTitleH1 title={t('Gift-Cards')} />

            {loads.tokens.map((token) => (
              <div
                style={{ paddingTop: '3px', paddingBottom: '3px' }}
                key={token.tokenNumber}
              >
                <ReceiptField name={t('Token')} value={token.tokenNumber} />
                <ReceiptField
                  name={t('Amount')}
                  value={<Money value={token.amount} currency={tagCurrency} />}
                />
              </div>
            ))}
          </div>
        )}

        <div style={{ paddingLeft: '12px', marginTop: '10px' }}>
          <ReceiptField name={t('Cards')} value={loads.tokens.length} />

          <ReceiptField
            name={t('Amount')}
            value={
              <Money
                value={loads.card_load_value_total}
                currency={tagCurrency}
              />
            }
          />
        </div>

        {loads?.additional_products &&
          loads?.additional_products.length !== 0 && (
            <div className="my-5">
              <ReceiptTitleH1 title={t('Additional-products')} />

              {loads.additional_products.map((product) =>
                AdditionalProducts(product),
              )}

              <div style={{ paddingLeft: '12px' }}>
                <div className="flex flex-row items-center">
                  <ReceiptField
                    name={t('Products')}
                    value={loads.additional_products.length}
                  />
                </div>
                <div className="flex flex-row items-center">
                  <ReceiptField
                    name={t('Amount')}
                    value={
                      <Money
                        value={loads.additional_products_total}
                        currency={tagCurrency}
                      />
                    }
                  />
                </div>
              </div>
            </div>
          )}

        <div className="my-5">
          <ReceiptTitleH1 title={t('Order-Recap')} />
          <ReceiptField name={t('Cards')} value={loads.tokens.length} />
          <ReceiptField
            name={t('Total-amount')}
            value={
              <Money
                value={loads.card_load_value_total}
                currency={tagCurrency}
              />
            }
          />
          <ReceiptField
            name={t('Total-fees')}
            value={
              <Money value={loads.card_load_fee_total} currency={tagCurrency} />
            }
          />
          <ReceiptField
            name={t('additional-product-total')}
            value={
              <Money
                value={loads.additional_products_total}
                currency={tagCurrency}
              />
            }
          />
          <ReceiptField
            name={t('payment-amount-total')}
            value={
              <Money
                value={loads.payment_amount_total}
                currency={tagCurrency}
              />
            }
          />
        </div>
      </DefaultReceipt>
    </div>
  );
});
