import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import SuccessAlert from '../Alert/SuccessAlert';
import FailAlert from '../Alert/FailAlert';
import { Form, FormControl, FormField, FormItem } from '../Form';
import { cardAPI, orderAPI } from '../../api';
import useApiHook from '../../hooks/useAPI';

export default forwardRef(function SendByEmailForm({ ticket }, ref) {
  const { t } = useTranslation();

  const methods = useForm({
    shouldUnregister: false,
    mode: 'onChange',
    defaultValues: {
      email: '',
    },
  });

  const email = methods.watch('email');

  const [sendEmailHandler, sendEmail] = useApiHook(async () => {
    if (ticket.token) {
      return cardAPI.sendReceiptByEmail({
        email,
        token: ticket.token,
        ticket: ticket.ticket_number,
      });
    }
    return orderAPI.sendReceiptByEmail({
      email,
      ticket_number: ticket.ticket_number,
    });
  });
  return (
    <>
      <div className="flex flex-col justify-start">
        <div className="mb-1 text-left">{t('Send the receipt by email')}</div>
        <Form {...methods}>
          <div className="flex flex-row gap-5">
            <FormField
              name="email"
              defaultValue={''}
              rules={{
                required: t('Required'),
              }}
              render={(field) => {
                return (
                  <FormItem className={'w-full'}>
                    <FormControl>
                      <input
                        {...field}
                        ref={ref}
                        placeholder={t('send-ticket-via-email')}
                        autoComplete="false"
                        onKeyDown={() => {
                          methods.clearErrors();
                          sendEmailHandler.clearErrors();
                        }}
                        className="w-full h-10 focus:border-kadozBlack pl-2 text-gfDarkBlue rounded border border-kadozSemiGray focus:outline-none"
                      />
                    </FormControl>
                  </FormItem>
                );
              }}
            />
            <button
              onClick={sendEmail}
              className={
                (sendEmailHandler.loading || email === ''
                  ? 'opacity-50 pointer-events-none '
                  : '') +
                'border rounded  py-2 px-8 text-gfDarkBlue w-32 bg-gfCoral font-MulishBold hover:bg-opacity-75  items-center justify-center flex flex-row focus:outline-none focus:border-kadozBlack font-medium'
              }
            >
              {t(sendEmailHandler.loading ? 'Sending' : 'Send')}
            </button>
          </div>
        </Form>
        <div className={'mt-2'}>
          {sendEmailHandler.success && (
            <SuccessAlert
              title="Success"
              message={t('Email-was-sent-successfully') + ' ' + email}
            />
          )}
          {sendEmailHandler.error && (
            <FailAlert message={sendEmailHandler.error} />
          )}
        </div>
      </div>
    </>
  );
});
