import React from 'react';
import { useTranslation } from 'react-i18next';
import CardInfoWidget from '../../../CardInfoWidget';
import { randomString } from '../../../../utils/Helper';

export default function Summaries({ summary, cards = [] }) {
  const { t } = useTranslation();

  return (
    <div className="mt-6 grid grid-cols-1 md:grid-cols-3 gap-5 md:gap-20">
      {cards.map((card) => {
        return (
          !!card.value && <CardInfoWidget key={randomString(3)} {...card} />
        );
      })}
    </div>
  );
}
