import React, { useEffect, useRef, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import { useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { Userpilot } from 'userpilot';
import {
  getManageSalesByDeliveryMethod,
  RESET_ACTIVATE_ORDER,
  RESET_MANAGE_SALES,
  RESET_ORDER_STATUS,
} from '../../../../actions/ManageSaleActions';
import { TAG_CHANGED } from '../../../../actions/TagActions';
import { TERMINAL_CHANGED } from '../../../../actions/TerminalActions';
import ClearIcon from '../../../../assets/svg/ClearIcon';
import SearchIcon from '../../../../assets/svg/SearchIcon';
import FailAlert from '../../../Alert/FailAlert';
import MessageAlert from '../../../Alert/MessageAlert';
import DropdownIndicator from '../../../Dropdown/Components/DropdownIndicator';
import SalesStatus from './SalesStatus';
import Container from '../../../UIComponents/Container';
import {
  GF_CORAL,
  GF_DARK_BLUE,
  GF_PERIWINKLE,
} from '../../../../utils/Constants';
import PrepareOrder from './PrepareOrder';
import ActivateOrder from './ActivateOrder';
import SendOrder from './SendOrder';
import ActiveOrder from './ActiveOrder';
import SetAsPaidOrder from './SetAsPaidOrder';
import ChevronUp from '../../../../assets/svg/ChevronUp';
import ChevronDown from '../../../../assets/svg/ChevronDown';
import { randomString } from '../../../../utils/Helper';
import { fetchCarriers } from '../../../../actions/CarriersActions';
import useAppConfig, { features } from '../../../../hooks/useAppConfig';
import Carrier from '../../../../enums/carrier.enum';

export default function ManageSales() {
  const dispatch = useDispatch();
  const { isFeatureEnabled } = useAppConfig();
  const { t } = useTranslation();
  const pageRef = useRef(null);
  const B2B_ORDERS_ENABLE = 1;
  const AWAITING_PAYMENT_METHOD = 1;

  const [selectedOrder, setSelectedOrder] = useState({
    value: 'all',
    label: `${t('All')}`,
    homeDeliver: null,
  });
  const [selectedCarrier, setSelectedCarrier] = useState({
    label: `${t('All-Carriers')}`,
    value: null,
  });

  const [selectedCustomerType, setSelectedCustomerType] = useState({
    value: 'b2c',
    label: `${t('business')}`,
  });
  const [searchKeyWord, setSearchKeyWord] = useState('');
  const [modalData, setModalData] = useState({});
  const [isOpenPrepareOrder, setIsOpenPrepareOrder] = useState(false);
  const [isOpenActivateOrder, setIsOpenActivateOrder] = useState(false);
  const [isOpenSendOrder, setIsSendOrder] = useState(false);
  const [isOpenActiveOrder, setIsActiveOrder] = useState(false);
  const [isOpenOrdered, setIsOpenOrdered] = useState(false);
  const { loadingManageSales, receivedManageSales, errorManageSales, meta } =
    useSelector((state) => state.manageSales);
  const { selectedTag } = useSelector((state) => state.tag);
  const [showMore, setShowMore] = useState(false);
  const [selectedID, setSelectedID] = useState('');
  const [selectedPageNumber, setSelectedPageNumber] = useState(0);
  const carrierEnabled = isFeatureEnabled(features.carriersInOrderSales);

  const { appCount } = useSelector((state) => state.commonReducer);
  const { carriers } = useSelector((state) => state.carriers);

  let deliveryStatus = [
    { value: 'all', label: `${t('all-delivery-methods')}`, homeDeliver: null },
  ];

  let deliveryCarriers = [
    {
      label: `${t('All-Carriers')}`,
      value: null,
    },
  ];

  if (carrierEnabled && carriers.length) {
    deliveryCarriers = [
      ...deliveryCarriers,
      ...carriers.map((data) => ({
        label: data.name,
        value: data.carrier_id,
      })),
    ];
  }

  if (meta && meta.has_home_delivery) {
    if (
      selectedTag.enable_e_shop_awaiting_payment_methode ===
      AWAITING_PAYMENT_METHOD
    ) {
      deliveryStatus = [
        ...deliveryStatus,
        {
          value: 'awaiting-payment',
          label: `${t('Home-delivery')} - ${t('awaiting-payment').toUpperCase()}`,
          homeDeliver: 1,
        },
      ];
    }
    deliveryStatus = [
      ...deliveryStatus,
      {
        value: 'to-fulfil',
        label: `${t('Home-delivery')} - ${t('Orders-to-fulfill').toUpperCase()}`,
        homeDeliver: 1,
      },
      {
        value: 'awaiting-delivery',
        label: `${t('Home-delivery')} - ${t('Awaiting-Delivery').toUpperCase()}`,
        homeDeliver: 1,
      },
      {
        value: 'sent',
        label: `${t('Home-delivery')} - ${t('Sent').toUpperCase()}`,
        homeDeliver: 1,
      },
      {
        value: 'active',
        label: `${t('Home-delivery')} - ${t('Active').toUpperCase()}`,
        homeDeliver: 1,
      },
    ];
  }

  if (meta && meta.has_pickup) {
    if (
      selectedTag.enable_e_shop_awaiting_payment_methode ===
      AWAITING_PAYMENT_METHOD
    ) {
      deliveryStatus = [
        ...deliveryStatus,
        {
          value: 'awaiting-payment-pick',
          label: `${t('Pick-up')} - ${t('awaiting-payment').toUpperCase()}`,
          homeDeliver: 0,
        },
      ];
    }

    deliveryStatus = [
      ...deliveryStatus,
      {
        value: 'to-pick-up',
        label: `${t('Pick-up')} - ${t('To-Pick-Up').toUpperCase()}`,
        homeDeliver: 0,
      },
      {
        value: 'picked-up',
        label: `${t('Pick-up')} - ${t('Picked-Up').toUpperCase()}`,
        homeDeliver: 0,
      },
    ];
  }

  const customerTypes = [
    { value: 'all', label: `${t('all-customers')}` },
    { value: 'b2b', label: `${t('business')}` },
    { value: 'b2c', label: `${t('individual')}` },
  ];

  useEffect(() => {
    Userpilot.reload();

    setSelectedOrder({
      value: 'all',
      label: `${t('all-delivery-methods')}`,
      homeDeliver: null,
    });
    setSelectedCustomerType({ value: 'b2c', label: `${t('individual')}` });

    setSearchKeyWord('');
    if (appCount === 0) {
      if (carrierEnabled) {
        dispatch(fetchCarriers('b2c'));
      }
      dispatch({ type: RESET_MANAGE_SALES });
      dispatch(
        getManageSalesByDeliveryMethod(
          selectedOrder.homeDeliver,
          selectedOrder.value,
          selectedCustomerType.value,
          searchKeyWord,
          selectedPageNumber,
        ),
      );
    }

    window.addEventListener(TAG_CHANGED, () => {});

    window.addEventListener(TERMINAL_CHANGED, () => {});

    return () => {
      window.removeEventListener(TAG_CHANGED, () => {});
      window.removeEventListener(TERMINAL_CHANGED, () => {});
    };
  }, []);

  const searchOnChangeHandler = (event) => {
    setSearchKeyWord(event.target.value);
  };

  const handlePagination = (event) => {
    setSelectedPageNumber(event.selected + 1);
    dispatch(
      getManageSalesByDeliveryMethod(
        selectedOrder.homeDeliver,
        selectedOrder.value,
        selectedCustomerType.value,
        searchKeyWord,
        event.selected + 1,
        null,
        null,
        selectedCarrier.value,
      ),
    );
  };

  const orderOnChanged = (event) => {
    if (pageRef.current !== null) pageRef.current.setState({ selected: 0 });
    setSelectedOrder(event);
    dispatch(
      getManageSalesByDeliveryMethod(
        event.homeDeliver,
        event.value,
        selectedCustomerType.value,
        searchKeyWord,
        selectedPageNumber,
        null,
        null,
        selectedCarrier.value,
      ),
    );
  };

  const carrierOnChanged = (event) => {
    if (pageRef.current !== null) pageRef.current.setState({ selected: 0 });
    setSelectedCarrier(event);
    dispatch(
      getManageSalesByDeliveryMethod(
        selectedOrder.homeDeliver,
        selectedOrder.value,
        selectedCustomerType.value,
        searchKeyWord,
        selectedPageNumber,
        null,
        null,
        event.value,
      ),
    );
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      boxShadow: 'none',
      borderColor: GF_PERIWINKLE,
      borderRadius: '4px',
      minHeight: '40px',
      paddingBottom: '2px',
      color: GF_DARK_BLUE,
      cursor: 'pointer',
      fontWeight: '600',
      maxWidth: '100%',

      '&:hover': {
        background: GF_PERIWINKLE,
        color: GF_DARK_BLUE,
      },

      '&:hover div': {
        color: GF_DARK_BLUE,
      },

      '&:focus': {
        borderColor: GF_PERIWINKLE,
        background: GF_PERIWINKLE,
      },
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: '8px',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? GF_DARK_BLUE : '#FFFFFF',
      color: state.isSelected ? '#FFFFFF' : GF_DARK_BLUE,
      '&:hover': {
        background: GF_CORAL,
        color: '#FFF',
      },
    }),

    menuList: (provided) => ({
      ...provided,
      maxHeight: '100%',
      color: GF_DARK_BLUE,
    }),

    singleValue: (provided) => ({
      ...provided,
      color: GF_DARK_BLUE,
      textTransform: 'capitalize',
    }),
  };

  const clearSearchBox = () => {
    setSearchKeyWord('');
    dispatch(
      getManageSalesByDeliveryMethod(
        selectedOrder.homeDeliver,
        selectedOrder.value,
        selectedCustomerType.value,
        '',
        selectedPageNumber,
        null,
        null,
        selectedCarrier.value,
      ),
    );
  };

  const openPrepareOrder = (data) => {
    document.body.style.overflow = 'hidden';
    setModalData(data);
    setIsOpenPrepareOrder(!isOpenPrepareOrder);
  };

  const closePrepareOrder = () => {
    document.body.style.overflow = 'unset';
    setModalData({});
    setIsOpenPrepareOrder(!isOpenPrepareOrder);
    dispatch({ type: RESET_ORDER_STATUS });
  };

  const openActivateOrder = (data) => {
    document.body.style.overflow = 'hidden';
    setModalData(data);
    setIsOpenActivateOrder(!isOpenActivateOrder);
  };

  const closeActivateOrder = () => {
    document.body.style.overflow = 'unset';
    setModalData({});
    setIsOpenActivateOrder(!isOpenActivateOrder);
    dispatch({ type: RESET_ACTIVATE_ORDER });
  };

  const openSendOrder = (data) => {
    document.body.style.overflow = 'hidden';
    setModalData(data);
    setIsSendOrder(!isOpenSendOrder);
  };

  const closeSendOrder = () => {
    document.body.style.overflow = 'unset';
    setModalData({});
    setIsSendOrder(!isOpenSendOrder);
    dispatch({ type: RESET_ORDER_STATUS });
  };

  const openActiveOrder = (data) => {
    document.body.style.overflow = 'hidden';
    setModalData(data);
    setIsActiveOrder(!isOpenActiveOrder);
  };

  const closeActiveOrder = () => {
    document.body.style.overflow = 'unset';
    setModalData({});
    setIsActiveOrder(!isOpenActiveOrder);
  };

  const openAlreadyOrdered = (data) => {
    document.body.style.overflow = 'hidden';
    setModalData(data);
    setIsOpenOrdered(!isOpenOrdered);
  };

  const closeAlreadyOrdered = () => {
    document.body.style.overflow = 'unset';
    setModalData({});
    setIsOpenOrdered(!isOpenOrdered);
  };

  const showHideMoreCards = (event, ticketNumber) => {
    if (event.currentTarget.id === ticketNumber) {
      setShowMore(!showMore);
      setSelectedID(ticketNumber);
    }
  };

  const searchHandler = () => {
    dispatch(
      getManageSalesByDeliveryMethod(
        selectedOrder.homeDeliver,
        selectedOrder.value,
        selectedCustomerType.value,
        searchKeyWord,
        selectedPageNumber,
        null,
        null,
        selectedCarrier.value,
      ),
    );
  };

  const selectedCustomerTypeHandler = (event) => {
    // if (pageRef.current !== null) pageRef.current.setState({ selected: 0 });
    setSelectedCustomerType(event);
    dispatch(
      getManageSalesByDeliveryMethod(
        selectedOrder.homeDeliver,
        selectedOrder.value,
        event.value,
        searchKeyWord,
        selectedPageNumber,
        null,
        null,
        selectedCarrier.value,
      ),
    );
  };

  const manageSalesDisplayName = selectedTag?.enable_e_shop_b2b_orders
    ? t('manage-sales-b2c')
    : t('Manage-sales');

  return (
    <Container title={manageSalesDisplayName} loading={loadingManageSales}>
      <div className="flex flex-col w-full mx-auto justify-start space-y-6">
        <div className="flex flex-col md:flex-row gap-10">
          <div className="flex flex-row gap-3">
            <div className="relative">
              {searchKeyWord !== '' && (
                <button
                  onClick={clearSearchBox}
                  className="focus:outline-none focus:shadow-outline"
                  style={{ position: 'absolute', right: '5px', top: '10px' }}
                >
                  <ClearIcon />
                </button>
              )}
              <input
                id="searchInput"
                value={searchKeyWord}
                onChange={searchOnChangeHandler}
                className="h-10 text-lg w-full placeholder-gfGrey  rounded border border-gfPeriwinkle focus:border-gfPeriwinkle pl-10 focus:outline-none"
                placeholder={`${t('Search')}...`}
                autoComplete="off"
              />
            </div>

            <button
              id="searchButton"
              onClick={searchHandler}
              className="border  flex flex-row rounded justify-center items-center  bg-gfCoral hover:bg-opacity-75 font-MulishBold  px-4 py-2 focus:outline-none"
            >
              <SearchIcon />
            </button>
          </div>
          <Select
            id="statusDropdown"
            className="w-96"
            styles={customStyles}
            value={selectedOrder}
            getOptionLabel={(option) => `${option.label}`}
            onChange={orderOnChanged}
            placeholder={false}
            options={deliveryStatus}
            isSearchable={false}
            components={{
              IndicatorSeparator: null,
              DropdownIndicator,
            }}
          />
          {carrierEnabled && (
            <Select
              id="carriersDropdown"
              className="w-96"
              styles={customStyles}
              value={selectedCarrier}
              getOptionLabel={(option) => `${option.label}`}
              onChange={carrierOnChanged}
              placeholder={false}
              options={deliveryCarriers}
              isSearchable={false}
              components={{
                IndicatorSeparator: null,
                DropdownIndicator,
              }}
            />
          )}

          {/* {selectedTag.enable_e_shop_b2b_orders === B2B_ORDERS_ENABLE && ( */}
          {/*    <Select */}
          {/*        className="w-96" */}
          {/*        styles={customStyles} */}
          {/*        value={selectedCustomerType} */}
          {/*        getOptionLabel={(option) => `${option.label}`} */}
          {/*        onChange={selectedCustomerTypeHandler} */}
          {/*        placeholder={false} */}
          {/*        options={customerTypes} */}
          {/*        isSearchable={false} */}
          {/*        components={{ */}
          {/*            IndicatorSeparator: null, */}
          {/*            DropdownIndicator: DropdownIndicator, */}
          {/*        }} */}
          {/*    /> */}
          {/* )} */}
        </div>

        {meta &&
          meta.total > window?.appConfig.REACT_APP_PAGINATION_PER_PAGE_ROWS && (
            <div className="flex flex-col md:flex-row md:justify-end w-full">
              <div>
                <ReactPaginate
                  ref={pageRef}
                  previousLabel={'prev'}
                  nextLabel={'next'}
                  breakLabel={'...'}
                  breakClassName={'break-me'}
                  pageCount={
                    meta.total /
                    window?.appConfig.REACT_APP_PAGINATION_PER_PAGE_ROWS
                  }
                  marginPagesDisplayed={4}
                  pageRangeDisplayed={5}
                  onPageChange={handlePagination}
                  containerClassName={'pagination'}
                  subContainerClassName={'pages pagination'}
                  activeClassName={'active'}
                />
              </div>
            </div>
          )}

        {receivedManageSales && receivedManageSales.length === 0 && (
          <div className="mt-10">
            <MessageAlert message={t('There-are-no-orders-to-display')} />
          </div>
        )}

        {errorManageSales && (
          <div className="mt-10">
            {errorManageSales && (
              <FailAlert message={errorManageSales.message} />
            )}
          </div>
        )}

        {receivedManageSales && receivedManageSales.length !== 0 && (
          <div className=" overflow-x-auto sm:overflow-x-auto md:overflow-x-auto rounded border ">
            <div className="align-middle inline-block min-w-full  ">
              <div className="shadow overflow-hidden  ">
                <div className="flex flex-row justify-start items-center border-b w-full px-4 py-2 text-xl font-MulishBold  text-gfDarkBlue bg-gfPeriwinkle bg-opacity-50">
                  {carrierEnabled
                    ? `${selectedOrder.label} - ${selectedCarrier.label} -  ${meta.total}`
                    : `${selectedOrder.label} - ${meta.total}`}
                </div>
                <table className="min-w-full divide-y divide-gfPeriwinkle">
                  <thead className="bg-gfGrey font-MulishBlack text-xs">
                    <tr>
                      <th
                        scope="col"
                        className="px-4 py-3  text-center   uppercase tracking-wider"
                      >
                        {t('global-order-ref')}
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-3  text-center   uppercase tracking-wider"
                      >
                        {t('Date')}
                      </th>
                      <th
                        scope="col"
                        className=" py-3  text-center   uppercase tracking-wider"
                      >
                        {t('Order-Ref')}
                      </th>
                      <th
                        scope="col"
                        className=" px-2 py-3  text-center   uppercase tracking-wider"
                      >
                        {t('Name')}
                      </th>
                      <th
                        scope="col"
                        className=" px-2 py-3  text-center   uppercase tracking-wider"
                      >
                        {t('Delivery-method')}
                      </th>
                      <th
                        scope="col"
                        className=" px-4 py-3  text-center   uppercase tracking-wider"
                      >
                        {t('Status')}
                      </th>
                      <th
                        scope="col"
                        className=" px-4 py-3  text-center   uppercase tracking-wider"
                      >
                        {t('Amount')}
                      </th>
                      <th
                        scope="col"
                        className=" px-4 py-3  text-center uppercase tracking-wider"
                      >
                        {t('Seller')}
                      </th>
                      <th
                        scope="col"
                        className=" px-4 py-3  text-center   uppercase tracking-wider"
                      >
                        {t('Action')}
                      </th>
                    </tr>
                  </thead>

                  <tbody className="bg-white divide-y divide-gfPeriwinkle text-sm">
                    {receivedManageSales.map((item) => (
                      <tr
                        key={randomString(6)}
                        className={`${item.orders.length > 1 ? 'shadow-md' : ''}`}
                        data-testid={
                          item.global_order_ref === null
                            ? item.orders[0].reference
                            : item.global_order_ref
                        }
                      >
                        <td
                          style={{ verticalAlign: 'top' }}
                          className="px-2 py-4 "
                          data-testid="globalOrderRef"
                        >
                          <div className="flex justify-center items-center">
                            {item.global_order_ref !== null ? (
                              <div>{item.global_order_ref}</div>
                            ) : (
                              <span>-</span>
                            )}
                          </div>
                        </td>

                        <td
                          style={{ verticalAlign: 'top' }}
                          className="px-4 py-4"
                          data-testid="date"
                        >
                          <div className="flex justify-center items-center">
                            {item.creation_date}
                          </div>
                        </td>

                        {item.orders.length === 1 && (
                          <td
                            style={{ verticalAlign: 'top' }}
                            className="px-2 py-4 "
                            data-testid="individualOrderRef"
                          >
                            <div className="flex justify-center items-center">
                              {item.orders[0].reference}
                            </div>
                          </td>
                        )}

                        {item.orders.length > 1 && (
                          <td
                            style={{ verticalAlign: 'top' }}
                            className="px-2 py-4 "
                            data-testid="individualOrderRefs"
                          >
                            <div className="flex flex-col justify-center items-center text-gfLightBlue font-MulishBlack">{`${item.orders.length} ${t('Orders')}`}</div>
                            <div
                              className={`${showMore && selectedID === item.global_order_ref ? ' flex flex-col justify-center items-center' : 'hidden'}`}
                            >
                              <table>
                                <tbody>
                                  {item.orders.map((entry) => (
                                    <tr key={entry.reference}>
                                      <td>
                                        <div className="py-1">
                                          {entry.reference}
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </td>
                        )}

                        {/* {item.orders.length > 1 &&  selectedTag.enable_e_shop_b2b_orders === B2B_ORDERS_ENABLE &&(
                                                    <td style={{ verticalAlign: "top" }} className="px-2 py-4 ">
                                                        <div className="flex flex-col justify-center items-center text-gfLightBlue font-MulishBlack">{`${item.orders.length}`}</div>
                                                        <div className={`${showMore && selectedID === item.global_order_ref ? " flex flex-col justify-center items-center" : "hidden"}`}>
                                                            <table>
                                                                <tbody>
                                                                    {item.orders.map((entry) => (
                                                                        <tr key={randomString(3)}>
                                                                            <td>
                                                                                {entry.customer_type === "b2c" && <div className="flex justify-center items-center">{t("individual")}</div>}
                                                                                {entry.customer_type === "b2b" && <div className="flex justify-center items-center">{t("business")}</div>}
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </td>
                                                )} */}

                        <td
                          style={{ verticalAlign: 'top' }}
                          className="px-2 py-4"
                          data-testid="name"
                        >
                          <div className="flex justify-center items-center">{`${item.first_name} ${item.last_name}`}</div>
                        </td>

                        <td
                          style={{ verticalAlign: 'top' }}
                          className="px-2 py-4"
                          data-testid="deliveryMethod"
                        >
                          {item.home_delivery === Carrier.TO_PICK_UP && (
                            <div className="flex justify-center items-center text-center">
                              {String(t('Pick-up')).toUpperCase()}
                              {carrierEnabled && item.carrier_name && (
                                <>
                                  <br />({item.carrier_name})
                                </>
                              )}
                            </div>
                          )}
                          {item.home_delivery === Carrier.HOME_DELIVERY && (
                            <div className="flex justify-center items-center text-center">
                              {String(t('Home-delivery')).toUpperCase()}
                              {carrierEnabled && item.carrier_name && (
                                <>
                                  <br />({item.carrier_name})
                                </>
                              )}
                            </div>
                          )}
                        </td>

                        {item.orders.length === 1 && (
                          <td
                            style={{ verticalAlign: 'top' }}
                            className="px-2 py-4"
                            data-testid="status"
                          >
                            <div className="flex justify-center items-center">
                              <SalesStatus
                                status={item.orders[0].display_status}
                              />
                            </div>
                          </td>
                        )}

                        {item.orders.length > 1 && (
                          <td
                            style={{ verticalAlign: 'top' }}
                            className="px-2 py-4"
                            data-testid="individualOrderStatus"
                          >
                            <div className="flex flex-col justify-center items-center text-gfLightBlue font-MulishBlack">
                              -
                            </div>
                            <div
                              className={`${showMore && selectedID === item.global_order_ref ? ' flex flex-col justify-center items-center' : 'hidden'}`}
                            >
                              <table>
                                <tbody>
                                  {item.orders.map((entry) => (
                                    <tr key={entry.reference}>
                                      <td style={{ height: '30px' }}>
                                        <div
                                          className="py-1"
                                          data-testid={entry.reference}
                                        >
                                          <SalesStatus
                                            status={entry.display_status}
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </td>
                        )}

                        {item.orders.length === 1 && (
                          <td
                            style={{ verticalAlign: 'top' }}
                            className="px-2 py-4"
                            data-testid="amount"
                          >
                            <div className="flex justify-center text-gfLightBlue">
                              <CurrencyFormat
                                decimalScale={2}
                                fixedDecimalScale={true}
                                value={
                                  item.orders[0].total_amount +
                                  item.orders[0].fee_amount
                                }
                                displayType={'text'}
                                thousandSeparator={true}
                              />
                              <span className="ml-2">{` ${selectedTag.currency}`}</span>
                            </div>
                          </td>
                        )}

                        {item.orders.length > 1 && (
                          <td
                            style={{ verticalAlign: 'top' }}
                            className="px-2 py-4"
                            data-testid="individualOrderAmount"
                          >
                            <div className="flex justify-center text-gfLightBlue font-MulishBlack">
                              <CurrencyFormat
                                decimalScale={2}
                                fixedDecimalScale={true}
                                value={item.orders.reduce(
                                  (a, pro) =>
                                    (a += parseFloat(
                                      pro.total_amount + pro.fee_amount,
                                    )),
                                  0,
                                )}
                                displayType={'text'}
                                thousandSeparator={true}
                              />
                              <span className="ml-2">{` ${selectedTag.currency}`}</span>
                            </div>
                            <div
                              className={`${showMore && selectedID === item.global_order_ref ? ' flex flex-col justify-center items-center' : 'hidden'}`}
                            >
                              <table>
                                <tbody>
                                  {item.orders.map((entry) => (
                                    <tr key={entry.reference}>
                                      <td>
                                        <div
                                          key={entry.id}
                                          className="flex flex-row py-1 "
                                        >
                                          <div
                                            className="flex justify-center text-gfLightBlue"
                                            data-testid={entry.reference}
                                          >
                                            <CurrencyFormat
                                              decimalScale={2}
                                              fixedDecimalScale={true}
                                              value={
                                                entry.total_amount +
                                                entry.fee_amount
                                              }
                                              displayType={'text'}
                                              thousandSeparator={true}
                                            />
                                            <span className="ml-2">{` ${selectedTag.currency}`}</span>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </td>
                        )}

                        <td
                          style={{ verticalAlign: 'top' }}
                          className="px-4 py-4"
                          data-testid="seller"
                        >
                          {item.customer_order_id !== null ? (
                            <div className="flex justify-center items-center">
                              GIFTIFY
                            </div>
                          ) : (
                            <div className="flex justify-center items-center">
                              CENTER
                            </div>
                          )}
                        </td>

                        {item.orders.length === 1 && (
                          <td
                            style={{ verticalAlign: 'top' }}
                            className="px-2 py-4"
                            data-testid="action"
                          >
                            <div className="flex justify-center items-center">
                              {item.orders[0].status === 'paid' && (
                                <button
                                  onClick={() =>
                                    openPrepareOrder(item.orders[0])
                                  }
                                  className="w-32 text-gfLightBlue hover:text-opacity-75 focus:outline-none hover:underline"
                                >
                                  {t('View-details')}
                                </button>
                              )}

                              {((item.orders[0].status === 'prepared' &&
                                item.home_delivery === 0) ||
                                item.orders[0].status === 'sent' ||
                                item.orders[0].status ===
                                  'partially_active') && (
                                <button
                                  onClick={() =>
                                    openActivateOrder(item.orders[0])
                                  }
                                  className="w-32 text-gfLightBlue hover:text-opacity-75 focus:outline-none hover:underline"
                                >
                                  {t('View-details')}
                                </button>
                              )}

                              {item.orders[0].status === 'prepared' &&
                                item.home_delivery === 1 && (
                                  <button
                                    onClick={() =>
                                      openSendOrder(item.orders[0])
                                    }
                                    className="w-32 text-gfLightBlue hover:text-opacity-75 focus:outline-none hover:underline"
                                  >
                                    {t('View-details')}
                                  </button>
                                )}

                              {item.orders[0].status === 'active' && (
                                <button
                                  onClick={() =>
                                    openActiveOrder(item.orders[0])
                                  }
                                  className="w-32 text-gfLightBlue hover:text-opacity-75 focus:outline-none hover:underline"
                                >
                                  {t('View-details')}
                                </button>
                              )}

                              {item.orders[0].status === 'ordered' && (
                                <button
                                  onClick={() =>
                                    openAlreadyOrdered(item.orders[0])
                                  }
                                  className="w-32 text-gfLightBlue hover:text-opacity-75 focus:outline-none hover:underline"
                                >
                                  {t('View-details')}
                                </button>
                              )}

                              {item.orders[0].status === 'awaiting_payment' && (
                                <button
                                  onClick={() =>
                                    openPrepareOrder(item.orders[0])
                                  }
                                  className="w-32 text-gfLightBlue hover:text-opacity-75 focus:outline-none hover:underline"
                                >
                                  {t('View-details')}
                                </button>
                              )}
                            </div>
                          </td>
                        )}

                        {item.orders.length > 1 && (
                          <td
                            style={{ verticalAlign: 'top' }}
                            className="px-2 py-4"
                            data-testid="individualOrderAction"
                          >
                            <div className="flex justify-center items-center">
                              <button
                                className="focus:outline-none"
                                id={item.global_order_ref}
                                onClick={(event) =>
                                  showHideMoreCards(
                                    event,
                                    item.global_order_ref,
                                  )
                                }
                              >
                                {showMore &&
                                selectedID === item.global_order_ref ? (
                                  <ChevronUp />
                                ) : (
                                  <ChevronDown />
                                )}
                              </button>
                            </div>
                            <div
                              className={`${showMore && selectedID === item.global_order_ref ? ' flex flex-col justify-center items-center' : 'hidden'}`}
                            >
                              <table>
                                <tbody>
                                  {item.orders.map((entry) => (
                                    <tr key={entry.reference}>
                                      <td
                                        style={{ height: '30px' }}
                                        data-testid={entry.reference}
                                      >
                                        {entry.status === 'paid' && (
                                          <button
                                            onClick={() =>
                                              openPrepareOrder(entry)
                                            }
                                            className="w-32 text-gfLightBlue hover:text-opacity-75 py-1 focus:outline-none hover:underline"
                                          >
                                            {t('View-details')}
                                          </button>
                                        )}

                                        {((entry.status === 'prepared' &&
                                          item.home_delivery === 0) ||
                                          entry.status === 'sent' ||
                                          entry.status ===
                                            'partially_active') && (
                                          <button
                                            onClick={() =>
                                              openActivateOrder(entry)
                                            }
                                            className="w-32 text-gfLightBlue hover:text-opacity-75 py-1 focus:outline-none hover:underline"
                                          >
                                            {t('View-details')}
                                          </button>
                                        )}

                                        {entry.status === 'prepared' &&
                                          item.home_delivery === 1 && (
                                            <button
                                              onClick={() =>
                                                openSendOrder(entry)
                                              }
                                              className="w-32 text-gfLightBlue hover:text-opacity-75 py-1 focus:outline-none hover:underline"
                                            >
                                              {t('View-details')}
                                            </button>
                                          )}

                                        {entry.status === 'active' && (
                                          <button
                                            onClick={() =>
                                              openActiveOrder(entry)
                                            }
                                            className="w-32 text-gfLightBlue hover:text-opacity-75 py-1 focus:outline-none hover:underline"
                                          >
                                            {t('View-details')}
                                          </button>
                                        )}

                                        {entry.status === 'ordered' && (
                                          <button
                                            onClick={() =>
                                              openAlreadyOrdered(entry)
                                            }
                                            className="w-32 text-gfLightBlue hover:text-opacity-75 py-1 focus:outline-none hover:underline"
                                          >
                                            {t('View-details')}
                                          </button>
                                        )}

                                        {entry.status ===
                                          'awaiting_payment' && (
                                          <button
                                            onClick={() =>
                                              openPrepareOrder(entry.status)
                                            }
                                            className="w-32 text-gfLightBlue hover:text-opacity-75 focus:outline-none hover:underline"
                                          >
                                            {t('View-details')}
                                          </button>
                                        )}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
      <PrepareOrder
        isOpen={isOpenPrepareOrder}
        data={modalData}
        closeHandler={closePrepareOrder}
        selectedOrder={selectedOrder}
        selectedCustomerType={selectedCustomerType}
        selectedCarrier={selectedCarrier}
      />
      <ActivateOrder
        isOpen={isOpenActivateOrder}
        data={modalData}
        closeHandler={closeActivateOrder}
        selectedOrder={selectedOrder}
        selectedCustomerType={selectedCustomerType}
        selectedCarrier={selectedCarrier}
      />
      <SendOrder
        isOpen={isOpenSendOrder}
        data={modalData}
        closeHandler={closeSendOrder}
        selectedOrder={selectedOrder}
        selectedCustomerType={selectedCustomerType}
        selectedCarrier={selectedCarrier}
      />
      <ActiveOrder
        isOpen={isOpenActiveOrder}
        data={modalData}
        closeHandler={closeActiveOrder}
      />
      <SetAsPaidOrder
        isOpen={isOpenOrdered}
        data={modalData}
        closeHandler={closeAlreadyOrdered}
      />
    </Container>
  );
}
