import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { issuingAPI } from '../services/Axios';
import useApiHook from './useAPI';
import { useMerchantCodes } from './useMerchantCodes';

function useMerchants(options = {}) {
  const { enabled = true } = options;

  const { tagCardBrands } = useSelector((state) => state.tag);
  const fetchMerchants = useCallback(
    async (params) => {
      return await issuingAPI.get(`card-brand/${tagCardBrands}/merchants`, {
        params,
      });
    },
    [tagCardBrands],
  );

  const [result, fetch] = useApiHook(fetchMerchants);

  useEffect(() => {
    if (enabled) {
      fetch();
    }
  }, [fetch, enabled]);

  return {
    merchants: result?.data?.data || [],
    fetch,
    isFetched: result.success === true,
    isLoading: result.loading,
    error: result.error,
  };
}

function useMerchantsForShoppingCentre() {
  const { tagID } = useSelector((state) => state.tag);

  const {
    fetch: fetchMerchants,
    isFetched,
    isLoading,
    merchants,
    error,
  } = useMerchants({ enabled: false });

  const fetchMerchantsForShoppingCentre = useCallback(
    (params) => {
      return fetchMerchants({
        ...params,
        tag_id: tagID,
      });
    },
    [fetchMerchants, tagID],
  );

  return {
    fetch: fetchMerchantsForShoppingCentre,
    isLoading,
    isFetched,
    merchants,
    error,
  };
}

function useMerchantCategories() {
  const { tagID } = useSelector((state) => state.tag);
  const { getDescriptionForCode } = useMerchantCodes();

  const fetchCategories = useCallback(
    async (params) => {
      return await issuingAPI.get(`tag/${tagID}/merchant-codes`, {
        params,
      });
    },
    [tagID],
  );

  const [result, fetch] = useApiHook(fetchCategories);
  const { data } = result;

  const categories = useMemo(() => {
    return (data || []).map((category) => ({
      ...category,
      name: getDescriptionForCode(category.code),
    }));
  }, [data, getDescriptionForCode]);

  return {
    fetch,
    categories,
  };
}

function useCreateMerchant() {
  const { tagCardBrands } = useSelector((state) => state.tag);

  async function createMerchant(payload) {
    const response = await issuingAPI.post(
      `card-brand/${tagCardBrands}/merchants`,
      payload,
    );

    return response.data;
  }

  return {
    createMerchant,
  };
}

export {
  useMerchants,
  useMerchantsForShoppingCentre,
  useCreateMerchant,
  useMerchantCategories,
};
