import { useCallback, useMemo, useState } from 'react';
import {
  useMerchantCategories,
  useMerchantsForShoppingCentre,
} from './useMerchants';
import { useDispatch, useSelector } from 'react-redux';
import { issuingAPI } from '../services/Axios';
import useApiHook from './useAPI';
import {
  APP_COUNT,
  ERROR_EXCEL_REPORT,
  LOADING_EXCEL_REPORT,
  RESET_APP_COUNT,
  SUCCESS_EXCEL_REPORT,
} from '../actions/CommonActions';
import {
  ERROR_CARD_USAGE_MERCHANT,
  ERROR_CARD_USAGE_MERCHANT_SUMMARY,
  LOADING_CARD_USAGE_MERCHANT_SUMMARY,
  PAGINATE_CARD_USAGE_MERCHANT,
  RECEIVED_CARD_USAGE_MERCHANT,
  RECEIVED_CARD_USAGE_MERCHANT_SUMMARY,
} from '../actions/CardUsageAtMerchantReportActions';
import { exportExcelFile } from '../utils/Helper';

const colors = [
  { hue: 217, saturation: 91 }, // #00adb5
  { hue: 360, saturation: 10 }, // #766161
  { hue: 195, saturation: 95 }, // #02475e
  { hue: 24, saturation: 100 }, // #ff6701
  { hue: 169, saturation: 54 }, // #206a5d
];

function generateDistinctColor(index, lightnessSteps = 20) {
  const baseColor = colors[index % colors.length];
  const lightness = 50 + (index % lightnessSteps) * (30 / lightnessSteps);

  return `hsl(${baseColor.hue}, ${baseColor.saturation}%, ${lightness}%)`;
}

const useMerchantUsageSummaries = () => {
  const { tagID } = useSelector((state) => state.tag);
  const fetchSummaries = useCallback(
    async (params) => {
      return await issuingAPI.get(`tag/${tagID}/merchant-usage-summaries`, {
        params,
      });
    },
    [tagID],
  );

  const fetchExport = useCallback(
    async (params) => {
      return await issuingAPI.get(`tag/${tagID}/merchant-usage-summaries`, {
        responseType: 'arraybuffer',
        params,
        headers: {
          Accept:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
      });
    },
    [tagID],
  );

  const [result, fetch] = useApiHook(fetchSummaries);

  return {
    fetch,
    isLoading: result.loading,
    error: result.error,
    isFetched: result.success === true,
    reset: result.reset,
    fetchExport,
  };
};

function useCardUsageSummary() {
  const [summary, setSummary] = useState([]);
  const {
    fetch: fetchMerchants,
    isFetched: hasFetchedMerchants,
    isLoading: isLoadingMerchants,
    merchants: merchantsData,
    error: merchantsError,
  } = useMerchantsForShoppingCentre();

  const {
    fetch: fetchSummaries,
    isLoading: isLoadingSummaries,
    error: summariesError,
    isFetched: hasFetchedSummaries,
    reset: resetSummaries,
    fetchExport: fetchSummariesExport,
  } = useMerchantUsageSummaries();

  const merchants = useMemo(
    () =>
      merchantsData.map((merchant, index) => ({
        ...merchant,
        color: generateDistinctColor(index),
      })),
    [merchantsData],
  );

  const fetchSummary = useCallback(
    async (params) => {
      try {
        const response = await fetchSummaries(params);

        const summary = response.data.data.map((summaryForMerchant) => {
          const color =
            merchants.find(
              (merchant) => merchant.id === summaryForMerchant.merchant_id,
            ).color ?? 'black';

          return {
            ...summaryForMerchant,
            color,
          };
        });

        setSummary(summary);
      } catch (error) {
        // Ignore?
      }
    },
    [fetchSummaries, merchants],
  );

  return {
    isLoading: isLoadingMerchants || isLoadingSummaries,
    error: summariesError || merchantsError,
    merchants,
    fetchMerchants,
    hasFetchedMerchants,
    hasMerchants: useMemo(() => merchants.length > 0, [merchants]),
    fetchSummary,
    summary,
    hasFetchedSummary: hasFetchedSummaries,
    exportSummary: fetchSummariesExport,
    clearSummary: () => {
      resetSummaries();
      setSummary([]);
    },
  };
}

function buildQueryParametersForUsage(payload) {
  const params = {
    giftify_merchants: 1,
  };

  if (payload.merchantId) {
    params.giftify_merchant_id = payload.merchantId;
  }

  if (payload.from) {
    params.from_date = payload.from;

    if (payload.to) {
      params.to_date = payload.to;
    }
  }

  if (payload.page) {
    params.page = payload.page;
    params.per_page = payload.perPage;
  }

  if (payload.merchantCode) {
    params.merchant_code = payload.merchantCode;
  }

  return params;
}

function fetchCardUsageTransactions(payload) {
  return (dispatch, getState) => {
    dispatch({ type: PAGINATE_CARD_USAGE_MERCHANT });
    dispatch({ type: APP_COUNT });

    const { tagID } = getState().tag;
    return issuingAPI
      .get(`/tag/${tagID}/merchant-transactions`, {
        params: buildQueryParametersForUsage(payload),
      })
      .then((success) => {
        dispatch({ type: RECEIVED_CARD_USAGE_MERCHANT, payload: success.data });
        return success;
      })
      .catch((error) => {
        dispatch({ type: ERROR_CARD_USAGE_MERCHANT, payload: error });
        dispatch({ type: RESET_APP_COUNT });
        return error;
      });
  };
}

function fetchCardUsageSummary(payload) {
  return (dispatch, getState) => {
    dispatch({ type: LOADING_CARD_USAGE_MERCHANT_SUMMARY });
    dispatch({ type: APP_COUNT });

    const { tagID } = getState().tag;
    return issuingAPI
      .get(`/tag/${tagID}/merchant-transactions/summary`, {
        params: buildQueryParametersForUsage(payload),
      })
      .then((success) => {
        dispatch({
          type: RECEIVED_CARD_USAGE_MERCHANT_SUMMARY,
          payload: success.data,
        });
        return success;
      })
      .catch((error) => {
        dispatch({ type: ERROR_CARD_USAGE_MERCHANT_SUMMARY, payload: error });
        dispatch({ type: RESET_APP_COUNT });
        return error;
      });
  };
}

function fetchTransactionsExport(payload) {
  return (dispatch, getState) => {
    dispatch({ type: LOADING_EXCEL_REPORT });

    const { tagID, selectedTag } = getState().tag;
    let fileName = `cards_usage_at_${selectedTag.name}`;

    if (payload.from) {
      fileName += `-${payload.from}`;
    }

    if (payload.to) {
      fileName += `-${payload.to}`;
    }

    return issuingAPI
      .get(`/tag/${tagID}/merchant-transactions/stream`, {
        responseType: 'arraybuffer',
        params: buildQueryParametersForUsage(payload),
        headers: {
          Accept:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
      })
      .then((response) => {
        dispatch({
          type: SUCCESS_EXCEL_REPORT,
          payload: response.data,
        });

        exportExcelFile(response.data, fileName);

        return response;
      })
      .catch((error) => {
        dispatch({ type: ERROR_EXCEL_REPORT, payload: error });
        return error;
      });
  };
}

function useCardUsage() {
  const dispatch = useDispatch();

  const {
    fetch: fetchMerchants,
    isLoading: isLoadingMerchants,
    merchants,
  } = useMerchantsForShoppingCentre();
  const { fetch: fetchMerchantCategories, categories } =
    useMerchantCategories();

  const fetchTransactions = useCallback(
    (params) => {
      dispatch(fetchCardUsageTransactions(params));
    },
    [dispatch],
  );

  const fetchSummary = useCallback(
    (params) => {
      dispatch(fetchCardUsageSummary(params));
    },
    [dispatch],
  );

  const exportTransactions = useCallback(
    (params) => {
      dispatch(fetchTransactionsExport(params));
    },
    [dispatch],
  );

  return {
    isLoading: isLoadingMerchants,
    merchants,
    fetchMerchants,
    fetchTransactions,
    fetchSummary,
    fetchMerchantCategories,
    exportTransactions,
    categories,
  };
}

export { useCardUsageSummary, useCardUsage };
